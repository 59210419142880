<template>
  <div>
    <h1 class="text-2xl font-bold mb-5">Demo</h1>

    <foo></foo>
  </div>
</template>

<script>
import foo from '@/components/ui/blog/page_sections/headers/basic_header_1';

export default {
  name: 'demo',
  components: { foo },
};
</script>

<style></style>
