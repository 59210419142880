<template>
  <!-- 
    this template requires the following font:
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
   -->
  <div class="header-container">
    <!-- action buttons -->
    <div class="actions-container">
      <!-- hidden on mobile -->
      <button class="hidden sm:block action">
        <HeartIcon class="h-5 w-5" />
      </button>
      <!-- hidden on mobile -->
      <button class="hidden sm:block action">
        <ShareIcon class="h-5 w-5" />
      </button>
      <!-- menu button for mobile -->
      <button class="block sm:hidden action">
        <DotsVerticalIcon class="h-5 w-5" />
      </button>
    </div>

    <!-- tags -->
    <div class="tags">
      <span
        v-for="tag in tags"
        :key="tag.title"
        class="tag"
        :class="tag.classes"
      >
        {{ tag.title }}
      </span>
    </div>

    <!-- title & category -->
    <div>
      <span class="category" :class="category.classes">
        {{ category.title }}
      </span>

      <h1 class="title relative">{{ title }}</h1>
    </div>

    <!-- info bar -->
    <div class="info-bar">
      <div class="flex space-x-2 items-center">
        <img
          class="avatar"
          :src="author.avatar"
          :alt="`${author.name}'s Avatar`"
        />
        <span class="author-name">{{ author.name }}</span>
      </div>

      <!-- post created date -->
      <span class="meta">{{ meta.created_at }}</span>

      <!-- comments -->
      <div class="hidden md:flex space-x-1 items-center">
        <ChatIcon class="h-5 w-5 meta" />
        <span class="meta">{{ meta.comments }}</span>
      </div>

      <!-- views -->
      <div class="hidden md:flex space-x-1 items-center">
        <EyeIcon class="h-5 w-5 meta" />
        <span class="meta">{{ meta.views }}</span>
      </div>
    </div>

    <!-- post brief description -->
    <h2 class="brief">{{ brief }}</h2>
  </div>
</template>

<script>
import {
  ChatIcon,
  EyeIcon,
  HeartIcon,
  ShareIcon,
  DotsVerticalIcon,
} from '@heroicons/vue/outline';

export default {
  name: 'basic header 1',
  components: { ChatIcon, EyeIcon, HeartIcon, ShareIcon, DotsVerticalIcon },
  data() {
    return {
      title: 'Chef Carlos Gaytan Wants You to Think Outside',
      brief:
        "There's more to an avocado — and avocado french fries are one delicious way to get your fix.",
      category: {
        title: 'Nature',
        classes: 'category-nature',
      },
      tags: [
        {
          title: 'movies',
          classes: 'tag-red',
        },
        {
          title: 'food',
          classes: 'tag-green',
        },
        {
          title: 'gossip',
          classes: 'tag-blue',
        },
      ],
      author: {
        name: 'Curtis Thronton',
        avatar:
          'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
      meta: {
        created_at: '04 Jun 2018',
        comments: 81,
        views: 719,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  @apply bg-white dark:bg-gray-800 relative flex flex-col space-y-4 sm:space-y-6 md:space-y-9 p-7 sm:p-10 md:p-16 lg:p-20 w-full;

  .actions-container {
    @apply absolute right-2 top-9 md:right-10 md:top-10 flex flex-row md:flex-col space-x-3 md:space-x-0 space-y-0 md:space-y-3;

    .action {
      @apply rounded-full p-2 text-gray-500 hover:text-gray-900 dark:hover:text-gray-300 border border-black dark:border-gray-600 border-opacity-5 hover:border-opacity-25;
    }
  }

  .tags {
    @apply flex space-x-2 w-full justify-center items-center;

    .tag {
      @apply rounded-lg px-2 py-1 text-xs font-bold uppercase;
    }
  }

  .category {
    @apply hidden sm:block max-w-md mx-auto sm:max-w-7xl text-xs uppercase font-bold border-b-2 border-blue-50 dark:border-gray-700;
  }

  .title {
    @apply text-2xl pt-5 sm:text-4xl md:text-6xl lg:text-7xl max-w-md mx-auto sm:max-w-7xl font-semibold text-center;
  }

  .brief {
    @apply text-lg md:text-2xl text-gray-600 dark:text-gray-400 max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-3xl text-center mx-auto;
  }

  .info-bar {
    @apply flex space-x-5 md:space-x-10 items-center max-w-3xl mx-auto;

    .avatar {
      @apply inline-block h-10 w-10 rounded-full object-cover;
    }

    .author-name {
      @apply font-semibold capitalize;
    }

    .meta {
      @apply text-gray-500 font-semibold text-sm;
    }
  }
}

.tag-red {
  @apply bg-red-100 dark:bg-red-700 text-red-700 dark:text-red-50;
}

.tag-green {
  @apply bg-green-100 dark:bg-green-700 text-green-700 dark:text-green-100;
}

.tag-blue {
  @apply bg-blue-100 dark:bg-blue-700 text-blue-700 dark:text-blue-100;
}

.category-nature {
  @apply text-blue-700 dark:text-blue-400;
}
</style>
